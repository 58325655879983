/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Context } from '@nuxt/types';
import c from 'cookie';
import handleMarketsAndLocales from '~/helpers/geminiConfig/handleMarketsAndLocales';

export default ({ app, route, redirect, req }: Context) => {
  const { getLocaleFromRoute } = handleMarketsAndLocales();
  // @ts-ignore
  const useCookieMarket = req?.query?.useCookieMarket || false;
  if (req) {
    const allCookies = app.$cookies.getAll();
    const cleanLocaleFromUrl = getLocaleFromRoute(route);
    if (allCookies && cleanLocaleFromUrl && cleanLocaleFromUrl !== '') {
      const {
        $config: { markets },
      } = app;
      if (useCookieMarket) {
        // @ts-ignore
        const cookiesObject = c.parse(req?.headers?.cookie);
        // @ts-ignore
        const cookieMarketGrn = cookiesObject['vsf-market'];
        const marketToRedirect = markets.find((object) => {
          return object.marketGrn === cookieMarketGrn;
        });
        const splitRoute = route.fullPath.split('/');
        splitRoute[1] = marketToRedirect.code;
        const newRoute = splitRoute.join('/');
        let noParameterRoute = newRoute.split('?')[0];
        if (Object.keys(route.query).includes('token')) {
          noParameterRoute = noParameterRoute + '?token=' + route.query.token;
        }
        redirect(302, noParameterRoute);
      }
    }
  }
};
