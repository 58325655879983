/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Context } from '@nuxt/types';
import cookieNameEnum from '~/enums/cookieNameEnum';
import c from 'cookie';
import handleMarketsAndLocales from '~/helpers/geminiConfig/handleMarketsAndLocales';

const checkCookiesAgainstRoute = (allCookies, marketGrn, language) => {
  const currentLocaleCookie = allCookies[cookieNameEnum.localeCookieName];
  const currentMarketCookie = allCookies[cookieNameEnum.marketCookieName];
  return !currentLocaleCookie || !currentMarketCookie || currentLocaleCookie !== language || currentMarketCookie !== marketGrn;
};

const updateAppCookies = (marketGrn, localeValue, defaultCurrency, appCookies) => {
  appCookies.set(cookieNameEnum.localeCookieName, localeValue);
  appCookies.set(cookieNameEnum.storeCookieName, localeValue);
  appCookies.set(cookieNameEnum.countryCookieName, localeValue);
  appCookies.set(cookieNameEnum.marketCookieName, marketGrn);
  appCookies.set(cookieNameEnum.currencyCookieName, defaultCurrency);
};

const handleLocaleAndMarketCookies = (marketGrn, localeValue, defaultCurrency, req) => {
  if (req?.headers?.cookie) {
    const cookiesObject = c.parse(req?.headers?.cookie);
    cookiesObject[cookieNameEnum.localeCookieName] = localeValue;
    cookiesObject[cookieNameEnum.storeCookieName] = localeValue;
    cookiesObject[cookieNameEnum.countryCookieName] = localeValue;
    cookiesObject[cookieNameEnum.marketCookieName] = marketGrn;
    cookiesObject[cookieNameEnum.currencyCookieName] = defaultCurrency;
    let newCookieString = '';
    Object.keys(cookiesObject).forEach((key) => {
      newCookieString += `${key}=${cookiesObject[key]}; `;
    });
    req.headers.cookie = newCookieString.trim().slice(0, -1);
  }
};

export default ({ app, route, req }: Context) => {
  const { findMarketForLocaleCode, getLocaleFromRoute } = handleMarketsAndLocales();
  // @ts-ignore
  const useCookieMarket = req?.query?.useCookieMarket || false;
  if (req) {
    const allCookies = app.$cookies.getAll();
    const cleanLocaleFromUrl = getLocaleFromRoute(route);
    if (allCookies && cleanLocaleFromUrl && cleanLocaleFromUrl !== '') {
      const {
        $config: { markets },
        $cookies: appCookies,
      } = app;
      const { marketGrn, localeValue, defaultCurrency } = findMarketForLocaleCode(markets, cleanLocaleFromUrl);
      const [, language] = cleanLocaleFromUrl.split('-');
      const shouldUpdateCookies = checkCookiesAgainstRoute(allCookies, marketGrn, language);
      if (shouldUpdateCookies && !useCookieMarket) {
        updateAppCookies(marketGrn, localeValue, defaultCurrency, appCookies);
        handleLocaleAndMarketCookies(marketGrn, localeValue, defaultCurrency, req);
      }
    }
  }
};
