import { Plugin } from '@nuxt/types';
import countries from 'i18n-iso-countries';
import it from 'i18n-iso-countries/langs/it.json';
import fr from 'i18n-iso-countries/langs/fr.json';
import de from 'i18n-iso-countries/langs/de.json';
import es from 'i18n-iso-countries/langs/es.json';
import en from 'i18n-iso-countries/langs/en.json';

countries.registerLocale(en);
countries.registerLocale(es);
countries.registerLocale(fr);
countries.registerLocale(it);
countries.registerLocale(de);

const plugin: Plugin = (context, inject) => {
  inject('i2c', (iso: string, localeParameter?: string): string => {
    const locale = localeParameter || context.i18n?.localeProperties?.localeValue;
    return countries.getName(iso, locale);
  });
};

export default plugin;
