/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { Context } from '@nuxt/types';
import cookieNameEnum from '~/enums/cookieNameEnum';
import localeMapEnum from '~/enums/localeMapEnum';
import c from 'cookie';
import handleMarketsAndLocales from '~/helpers/geminiConfig/handleMarketsAndLocales';

export default ({ app, route, req }: Context) => {
  if (req) {
    const allCookies = app.$cookies.getAll();
    const { getLocaleFromRoute } = handleMarketsAndLocales();
    if (allCookies) {
      const { $cookies: appCookies } = app;
      const cleanLocaleFromUrl = getLocaleFromRoute(route).split('-')[1];
      if (
        !allCookies[cookieNameEnum.acceptLanguageCookieName] ||
        localeMapEnum[cleanLocaleFromUrl] !== localeMapEnum[allCookies[cookieNameEnum.acceptLanguageCookieName]]
      ) {
        appCookies.set(cookieNameEnum.acceptLanguageCookieName, localeMapEnum[cleanLocaleFromUrl]);
        if (req?.headers?.cookie) {
          const cookiesObject = c.parse(req?.headers?.cookie);
          cookiesObject[cookieNameEnum.acceptLanguageCookieName] = localeMapEnum[cleanLocaleFromUrl];
          let newCookieString = '';
          Object.keys(cookiesObject).forEach((key) => {
            newCookieString += `${key}=${cookiesObject[key]}; `;
          });
          req.headers.cookie = newCookieString.trim().slice(0, -1);
        }
      }
    }
  }
};
