// eslint-disable-next-line import/no-extraneous-dependencies
import { Plugin } from '@nuxt/types';
import formatCurrency from '~/helpers/formatCurrency';

const plugin: Plugin = (context, inject) => {
  inject('fc', (value: number | string, locale?: string, options = {}): string => {
    // eslint-disable-next-line no-param-reassign
    locale = locale || context.i18n?.localeProperties?.localeValue;
    // eslint-disable-next-line no-param-reassign
    options = { currency: context.app.$vsf.$gemini.config.state.getCurrency() || context.i18n?.localeProperties?.defaultCurrency, ...options };
    return formatCurrency(value, locale, options);
  });
};

export default plugin;
