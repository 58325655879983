<template>
  <div>
    <style v-if="realWindowHeight">
      :root {
        --real-window-height: {{ `${realWindowHeight}px` }};
      }
    </style>
    <Sidebars />
    <Modals :localized-country="localizedCountry" />
    <SearchModal />
    <LazyHydrate when-visible>
      <Notification />
    </LazyHydrate>
    <TopBar />
    <div id="layout">
      <AppHeader />
      <nuxt :key="route.fullPath" />
    </div>
    <LazyHydrate when-visible>
      <EkomiWidget />
    </LazyHydrate>
    <LazyHydrate when-visible>
      <AppFooter />
    </LazyHydrate>
  </div>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration';
import {
  useRoute,
  defineComponent,
  onMounted,
  useContext,
  computed,
  ref,
} from '@nuxtjs/composition-api';
import AppHeader from '~/components/Header/AppHeader.vue';
import TopBar from '~/components/TopBar.vue';
import Notification from '~/components/Notification.vue';
import EkomiWidget from '~/components/EkomiWidget.vue';
import Sidebars from '~/components/Sidebars/Sidebars.vue';
import { useWindow, useCart, useGeoLocation, useUiState } from '~/composables';
import { useI18n } from '~/helpers/hooks/usei18n';
import { botsRegExp } from '~/helpers/util';
import Modals from '~/components/Modals/Modals.vue';
import SearchModal from '~/components/Search/SearchModal.vue';
import useWishlist from '~/composables/useWishlist';
import AppFooter from '~/components/AppFooter.vue';

export default defineComponent({
  name: 'DefaultLayout',

  components: {
    LazyHydrate,
    TopBar,
    AppHeader,
    AppFooter,
    Notification,
    Sidebars,
    Modals,
    EkomiWidget,
    SearchModal,
  },

  setup() {
    /* eslint-disable sonarjs/cognitive-complexity */
    const route = useRoute();
    const { load: loadCart } = useCart();
    const { isDesktop, realWindowHeight } = useWindow();
    const { loadWishlist } = useWishlist();
    const { locale } = useI18n();
    const {
      $vsf: {
        $gemini: { config },
      },
      app: {
        $device: { userAgent },
      },
    } = useContext();
    const { getLocation, location, error: locatorError } = useGeoLocation();
    const { toggleModal } = useUiState();
    const countryCookieName = 'vsf-localized-country';
    const localizedCountry = ref(null);
    const canUseGeoLocator = computed(
      () =>
        !botsRegExp.test(userAgent) &&
        config?.geoLocator?.enable &&
        process &&
        process.client &&
        localStorage
    );

    onMounted(async () => {
      loadCart();
      loadWishlist();
      if (
        canUseGeoLocator.value &&
        (localStorage.getItem(countryCookieName) === null ||
          localStorage.getItem('vsf-country-selected') === null)
      ) {
        await getLocation();
        if (locatorError.value) {
          console.error(`Error in GeoLocator API:`, locatorError.value);
          if (localStorage.getItem('vsf-country-selected') === null) {
            toggleModal('country');
          }
        } else {
          localStorage.setItem(
            countryCookieName,
            JSON.stringify({
              label: location.value?.country_name,
              code: location.value?.country_code?.toLowerCase(),
              flag: location.value?.location?.country_flag,
            })
          );
          localizedCountry.value = {
            label: location.value?.country_name,
            code: location.value?.country_code?.toLowerCase(),
            flag: location.value?.location?.country_flag,
          };
          if (
            localizedCountry.value.code &&
            locale.split('-')[0] !== localizedCountry.value.code
          ) {
            toggleModal('geolocator');
          }
        }
      } else if (
        canUseGeoLocator.value &&
        localStorage.getItem(countryCookieName) !== null
      ) {
        localizedCountry.value = JSON.parse(
          localStorage.getItem(countryCookieName)
        );
      }
    });

    return {
      route,
      isDesktop,
      realWindowHeight,
      localizedCountry,
    };
  },
});
</script>

<style lang="scss">
#hubspot-messages-iframe-container {
  z-index: 4 !important;
}
#layout {
  box-sizing: border-box;
  @include desktop-boxed;
  margin: 0 auto;
}

// ----------- Common style -----------

// Titles
h1 {
  @include mobile-h1;

  @include from-desktop-min {
    @include desktop-h1;
  }
}

h2 {
  @include mobile-h2;

  @include from-desktop-min {
    @include desktop-h2;
  }
}

h3 {
  @include mobile-h3;

  @include from-desktop-min {
    @include desktop-h3;
  }
}

h4 {
  @include mobile-h4;

  @include from-desktop-min {
    @include desktop-h4;
  }
}

h5 {
  @include mobile-h5;

  @include from-desktop-min {
    @include desktop-h5;
  }
}

h6 {
  @include mobile-h6;

  @include from-desktop-min {
    @include desktop-h6;
  }
}

// Reset CSS
html {
  width: auto;

  @include for-mobile {
    overflow-x: hidden;
  }
}

// Other
html.no-scroll {
  overflow: hidden;
  left: 0;
  top: 0;
  right: 0;

  bottom: 0;
  position: relative;
}

body {
  overflow-x: hidden;
  color: var(--c-text);
  font-size: var(--font-size--base);
  font-family: var(--font-family--primary);
  margin: 0;
  padding: 0;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

body.loading_search {
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
  opacity: 0.8;
  position: relative;
  top: 0;
  left: 0;
}

body.loading_search::before {
  content: '';
  top: 50%;
  left: calc(50% - 1.25rem);
  width: 2.5rem;
  height: 2.5rem;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #000;
  animation: rotate 0.8s infinite linear;
  -webkit-animation: rotate 0.8s infinite linear;
  transform: translate(-50%, -50%);
  position: fixed;
  z-index: 9999;

  @include to-tablet-max {
    z-index: 99999999;
  }
}

a {
  text-decoration: none;
}

* {
  scrollbar-width: auto;
  scrollbar-color: var(--c-light-gray) var(--c-white);
}

*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: var(--c-white);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--c-light-gray);
  border-radius: 10px;
  border: 3px solid var(--c-white);
}

.smartphone-only {
  @include for-desktop {
    display: none !important;
  }
}
.desktop-only {
  @include for-mobile {
    display: none !important;
  }
}

// ----- START move style to fix console error related to css chunk -----
.user-address-details {
  border: var(--general-border);
  border-radius: 0.625rem;
  &__inner {
    margin: 1.25rem;
    &__row {
      @include paragraph-m;
      margin-bottom: 0.3125rem;
      &:first-child {
        @include desktop-h7;
      }
    }
    &__actions {
      margin-top: 1.25rem;
      display: flex;
      &__action {
        margin-right: 1.0625rem;
      }
    }
  }
}

.iubenda-tp-alert-btn[data-tp-float][data-tp-float='bottom-right'],
.iubenda-tp-alert-btn[data-tp-float][data-tp-float='top-right'],
.iubenda-tp-btn[data-tp-float][data-tp-float='bottom-right'],
.iubenda-tp-btn[data-tp-float][data-tp-float='top-right'],
.iubenda-uspr-btn[data-tp-float][data-tp-float='bottom-right'],
.iubenda-uspr-btn[data-tp-float][data-tp-float='top-right'],
#iubenda-cs-preferences-link-selector {
  aspect-ratio: 1/1;
  position: relative !important;
  bottom: 30px !important;
  left: 50px !important;
  margin: 0 !important;

  @include from-desktop-min {
    bottom: 60px !important;
    left: 500px !important;
  }
}

// ----- END move style to fix console error related to css chunk -----
</style>
