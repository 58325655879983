import { Context } from '@nuxt/types';
import cookieNames from '~/enums/cookieNameEnum';
import { hasAuthorizationError } from '~/helpers/util';
import { magentoStoreviewCodesMapping } from '~/helpers/storeviewMapping';
import { redirects } from '~/helpers/redirects';
import { ref } from '@nuxtjs/composition-api';

export default ({ app, route, redirect }: Context) => {
  const path = route.fullPath?.split('/')[1];

  redirects.forEach((element) => {
    if (route.fullPath === element.from) {
      const redirectType = element.type ?? 301;
      redirect(redirectType, element.to);
    }
  });

  magentoStoreviewCodesMapping.forEach((element) => {
    if (path === element.magentoStoreViewCode) {
      const mCode = `/${element.magentoStoreViewCode}`;
      const gCode = `/${element.geminiCode}`;
      const toRedirect = ref(`${route.fullPath.replace(mCode, gCode)}`);
      toRedirect.value = toRedirect.value.replace('.html', '');
      redirect(301, toRedirect.value);
    }
  });

  if (hasAuthorizationError(app.$cookies.get(cookieNames.customerCookieName))) {
    app.$cookies.remove(cookieNames.customerCookieName);
    app.$cookies.remove(cookieNames.cartCookieName);
    app.$cookies.remove(cookieNames.segmentsCookieName);
    app.$cookies.set(cookieNames.messageCookieName, {
      message: app.i18n.t('You are not authorized, please log in.'),
      type: 'warning',
      icon: null,
      persist: false,
      title: null,
    });
  }
};
