/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Context } from '@nuxt/types';
import cookieNames from '~/enums/cookieNameEnum';
import handleMarketsAndLocales from '~/helpers/geminiConfig/handleMarketsAndLocales';
import localeMapEnum from '~/enums/localeMapEnum';

const localeMatchesStateAndCookies = (state, appCookies, localeValue) => {
  return (
    state.getLocale() === appCookies.get(cookieNames.localeCookieName) &&
    state.getStore() === appCookies.get(cookieNames.storeCookieName) &&
    state.getCountry() === appCookies.get(cookieNames.countryCookieName) &&
    state.getMarket() === appCookies.get(cookieNames.marketCookieName) &&
    state.getCurrency() === appCookies.get(cookieNames.currencyCookieName) &&
    state.getAcceptLanguage() === localeMapEnum[localeValue]
  );
};

export default ({ app, route }: Context) =>
  app.$vsf.$gemini.client.interceptors.request.use((request) => {
    const {
      $config: { markets },
      $cookies: appCookies,
      $vsf: {
        $gemini: {
          config: { state },
        },
      },
      i18n: { localeProperties: i18nLocale },
    } = app;

    const { findMarketForLocaleCode, getLocaleFromRoute } = handleMarketsAndLocales();

    if (process.client) {
      const localeCodeFromRoute = getLocaleFromRoute(route);
      if (localeCodeFromRoute && localeCodeFromRoute !== '') {
        const { marketGrn, localeValue, defaultCurrency } = findMarketForLocaleCode(markets, localeCodeFromRoute);
        const stateAndCookiesMatch = localeMatchesStateAndCookies(state, appCookies, localeValue);
        const marketCookiesMatchesUrl = appCookies.get(cookieNames.marketCookieName) === marketGrn;
        const i18nLocaleMatchesPath = i18nLocale.code === localeCodeFromRoute;
        const acceptLanguageMatchesRoute = localeMapEnum[appCookies.get(cookieNames.acceptLanguageCookieName)] === localeValue;

        const shouldUpdateState = !stateAndCookiesMatch || !marketCookiesMatchesUrl || !i18nLocaleMatchesPath || !acceptLanguageMatchesRoute;

        if (shouldUpdateState) {
          state.setStore(localeValue);
          state.setLocale(localeValue);
          state.setAcceptLanguage(localeMapEnum[localeValue]);
          state.setCountry(localeValue);
          state.setMarket(marketGrn);
          state.setCurrency(defaultCurrency);
        }
      }
    }
    return request;
  });
