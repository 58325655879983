export default {
  cookies: {
    currencyCookieName: 'vsf-currency',
    countryCookieName: 'vsf-country',
    localeCookieName: 'vsf-locale',
    cartCookieName: 'vsf-cart',
    customerCookieName: 'vsf-customer',
    storeCookieName: 'vsf-store',
    messageCookieName: 'vsf-message',
    acceptLanguageCookieName: 'vsf-accept-language',
  },
};
